<template>
    <v-autocomplete
            v-model="inputValue"
            :items="items"
            :search-input.sync="search"
            cache-items
            hide-no-data
            hide-details
            autofocus
            :clearable="clearable"
    >
        <template v-slot:append>
            <slot name="append"></slot>
        </template>
        <template v-slot:append-outer>
            <slot name="append-outer"></slot>
        </template>
        <template v-slot:prepend>
            <slot name="prepend"></slot>
        </template>
    </v-autocomplete>
</template>

<script>
    import autocomplete from '@/mixins/components/autocomplete'
    export default {
        mixins: [autocomplete],
        name: "OfferAutocomplete",
        props: {
            clearable: {},
            initialSearch: {},
        },
        data(){
            return {
                search: '',
                autocompleteRoute: 'cpaNetworkManager.offers.autocomplete'
            }
        },
        watch: {
            search (val) {
                val && val !== this.select && this.itemAutocomplete(val)
            },
        },
        mounted() {
            if (this.initialSearch){
                this.itemAutocomplete(this.initialSearch);
            }
        }
    }
</script>

<style scoped>

</style>
