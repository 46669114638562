<template>
    <v-container fluid>
        <v-data-table
                :headers="headers"
                :items="paginationItems.data"
                :items-per-page="paginationParams.per_page"
                :page="paginationParams.page"
                :server-items-length="paginationItems.total"
                :loading="loading"
                sort-by="id"
                class="border"
                @update:page="changePage"
                @update:items-per-page="changeItemsPerPage"
                :footer-props="footerProps"
                @update:sort-by="sortBy"
                @update:sort-desc="sortDesc"
        >
            <template v-slot:top>
                <v-container>
                    <v-row>
                        <v-col style="min-width: 200px;">
                            <v-text-field
                                    v-model="filters.label"
                                    :label="trans('fields.common.label')"
                                    clearable
                            ></v-text-field>
                        </v-col>
                        <v-col style="min-width: 200px;">
                            <v-text-field
                                    v-model="filters.foreign_id"
                                    label="foreign_id"
                                    clearable
                            ></v-text-field>
                        </v-col>
                        <v-col style="min-width: 200px;">
                            <v-select
                                    :items="linkedOptions"
                                    :label="trans('manage.offer.linkedSelector')"
                                    :multiple="false"
                                    v-model="filters.linked"
                                    clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn
                                class="ma-2"
                                color="primary"
                                outlined
                                @click="applyFilters"
                        >{{ trans('buttons.common.apply') }}</v-btn>
                        <v-btn
                                class="ma-2"
                                color="orange"
                                outlined
                                @click="clearFilters"
                        >{{ trans('buttons.common.clear') }}</v-btn>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon small color="red" @click="deleteDialogShow(item.id)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.offer="{ item }">
                <offer-link-form
                        :offer_id="item.offer_id"
                        :pre-search="!item.offer_id ? item.label.substr(0, 3) : ''"
                        :label="item.offer ? item.offer.label + (' (' + item.offer.language.locale + ')') + (item.offer.scope === 1 ? ' (' + trans('offer.scope.private') + ')' : '') + (item.offer.scope === 2 ? ' (' + trans('offer.scope.personal') + ')' : '') : trans('manage.offer.notAssigned')"
                        :label-class="item.offer ? '' : 'red--text'"
                        :foreign_id="item.foreign_id"
                        v-on:updated="initialize"
                ></offer-link-form>
            </template>
            <template v-slot:item.status="{ item }">
                <v-icon v-if="item.status" color="green" :title="trans('manage.offer.status.online')">mdi-circle-slice-8</v-icon>
                <v-icon v-else color="red" :title="trans('manage.offer.status.offline')">mdi-circle-slice-8</v-icon>
            </template>
<!--            <template v-slot:item.countries="{ item }">-->
<!--                {{ options.countriesKeyed ? item.countriesArr.map((id) => options.countriesKeyed[id]).join(', ') : '' }}-->
<!--            </template>-->
<!--            <template v-slot:item.created_at="{ item }">-->
<!--                {{ item.created_at | mysqlDate }}-->
<!--            </template>-->
<!--            <template v-slot:item.scope="{ item }">-->
<!--                {{ options.offerScopesKeyed ? options.offerScopesKeyed[item.scope] : '' }}-->
<!--            </template>-->
<!--            <template v-slot:item.user="{ item }">-->
<!--                <user-link :user="item.user"></user-link>-->
<!--            </template>-->

        </v-data-table>
    </v-container>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import tableFilters from "@/mixins/tableFilters";
    import OfferLinkForm from "@/components/cpaNetworkManager/offer/OfferLinkForm";

    export default {
        name: "OfferTable",
        components: {
            OfferLinkForm
        },
        mixins: [tableWithPagination, tableFilters],
        data() {
            return {
                offers: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: "foreign_id", sortable: false, value: "foreign_id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.offer"), value: "offer", sortable: false },
                    { text: this.trans("fields.common.status"), value: "status", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                filters: {
                    label: '',
                    linked: '',
                    foreign_id: '',
                },
                linkedOptions: [
                    {text: this.trans('manage.offer.linkedOptions.all'), value: ''},
                    {text: this.trans('manage.offer.linkedOptions.linked'), value: 2},
                    {text: this.trans('manage.offer.linkedOptions.unlinked'), value: 1}
                ],
                url: this.route('cpaNetworkManager.offers.index', this.$route.params.id),
            }
        },
        created() {
            this.loadOptions({languages: true, offerScopes: true, offerTypes: true, countries: true});
        },
        watch: {
        },
        methods: {

        },
        computed: {
            paginationItems: {
                get(){
                    return this.offers;
                },
                set(v){
                    this.offers = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
