<template>
    <div v-if="!showForm">
        <v-btn icon @click="showForm = !showForm">
            <v-icon>mdi-link-variant</v-icon>
        </v-btn>
        <span :class="labelClass">{{ label }}</span>
    </div>
    <div v-else>
        <offer-autocomplete :initial-search="preSearch" :disabled="loading" v-model="form.offer_id" class="mt-0" :clearable="true">
            <template v-slot:append-outer>
                <v-btn icon :title="trans('buttons.common.save')" color="primary" @click="save">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </template>
            <template v-slot:prepend>
                <v-btn icon :title="trans('buttons.common.cancel')" @click="showForm = !showForm">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </offer-autocomplete>
    </div>
</template>

<script>
    import OfferAutocomplete from "@/components/cpaNetworkManager/offer/OfferAutocomplete";
    export default {
        name: "OfferLinkForm",
        components: {OfferAutocomplete},
        props: {
            foreign_id: {
                type: [Number, String, undefined, null]
            },
            offer_id: {
                type: [Number, undefined, null]
            },
            label: {
                type: [String, undefined, null]
            },
            labelClass: {
                type: [String, undefined, null]
            },
            preSearch: {
                type: [String, undefined, null]
            },
        },
        data(){
            return {
                showForm: false,
                loading: false,
                form: {
                    offer_id: this.offer_id,
                    foreign_id: this.foreign_id,
                }
            }
        },
        methods: {
            save(){
                this.loading = true;
                axios.post(this.route('cpaNetworkManager.offers.link', this.$route.params.id), this.form).then( () => {
                    this.showForm = false;
                    this.$emit('updated');
                }).catch(err => {
                    console.log(err)
                }).then( () => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>